import { useMemo } from 'react';

import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { BlogLink, GatsbyLink, PartnerLink } from 'components/Links';

import Calculator from 'images/shared-page-assets/Calculator';
import ChartScatter from 'images/shared-page-assets/ChartScatter';
import Gauge from 'images/shared-page-assets/Gauge';
import type { Translations } from 'helpers/mergeTranslations';

export const usePerformanceContent = () => [
  {
    header: 'Powerful investment performance reports',
    textContent: (
      <p>
        Sharesight offers an advanced reporting suite designed by investors, for the needs of
        investors like you. Our reporting suite includes everything you need to evaluate your
        investment portfolio performance, calculate your portfolio diversity and make smarter
        investment decisions today, tomorrow, and beyond.
      </p>
    ),
    image: useGatsbyImage({
      name: 'investment-portfolio-performance/powerful-investment-performance-reports',
      alt: 'Powerful investment performance reports',
    }),
  },
  {
    header: 'Track investment performance over any period',
    textContent: (
      <p>
        Calculate the impact of capital gains, dividends and currency fluctuations on your portfolio
        with the{' '}
        <BlogLink to="track-your-investments-with-sharesights-performance-report">
          Performance Report
        </BlogLink>
        . Break down your performance along a range of preset and custom dimensions including
        country, market, industry and more. Compare how different investments perform within your
        portfolio and add custom labels to further filter the report.
      </p>
    ),
    image: useGatsbyImage({
      name: 'investment-portfolio-performance/track-investment-performance-over-any-period',
      alt: 'Track investment performance over any period',
    }),
  },
  {
    header: 'Compare your performance over multiple periods',
    textContent: (
      <p>
        Measure the performance of your investments over up to 5 distinct or cumulative time periods
        with the <BlogLink to="multi-period-report">Multi-Period Report</BlogLink>. View your
        portfolio’s capital gains, payout gains and currency gains in percentage or monetary values
        across time.
      </p>
    ),
    image: useGatsbyImage({
      name: 'investment-portfolio-performance/compare-your-performance-over-multiple-periods',
      alt: 'Compare your performance over multiple periods',
    }),
  },
  {
    header: 'Visualise your portfolio diversity',
    textContent: (
      <p>
        Track your asset allocation and calculate your portfolio diversity across{' '}
        <PartnerLink to="/partners/factset" title="FACTSET partner | Sharesight New Zealand">
          FACTSET
        </PartnerLink>{' '}
        investment classifications or your own groupings with the{' '}
        <BlogLink to="calculate-investment-portfolio-diversification-sharesight">
          Diversity Report
        </BlogLink>
        . This makes it easy to rebalance your portfolio to your target asset allocation.
      </p>
    ),
    image: useGatsbyImage({
      name: 'investment-portfolio-performance/visualise-your-portfolio-diversity',
      alt: 'Visualise your portfolio diversity',
    }),
  },
];

export const usePerformanceFAQ = () => {
  const {
    translations: {
      freeUsers,
      stock,
      trackedInvestments,
      mutualFund,
      supportedBrokers,
      supportedCurrencies,
    },
  } = useContentfulLocale();

  return useMemo(
    () => [
      {
        question: 'How do you calculate the performance of an investment portfolio?',
        answer: (
          <p>
            Sharesight’s investment portfolio management software gives investors the full picture
            of their portfolio’s performance by including the impact of capital gains, brokerage
            fees, dividends and currency fluctuations when evaluating investment portfolio returns.
            Rather than simply stating the difference between an investment’s buy and sell (or
            current) price, Sharesight also{' '}
            <BlogLink to="how-sharesight-calculates-your-investment-performance">
              calculates returns
            </BlogLink>{' '}
            on an annualised basis. Displaying the annual return allows investors to see their rate
            of return and compare the relative performance of their investments (and portfolio) over
            the same and different time periods. To view a portfolio’s performance data at a glance,
            investors can also use Sharesight’s investment portfolio{' '}
            <BlogLink to="track-your-investments-with-sharesights-performance-report">
              Performance Report
            </BlogLink>
            .
          </p>
        ),
      },
      {
        question: 'How to calculate portfolio diversity?',
        answer: (
          <p>
            Sharesight users can easily calculate {stock} portfolio diversity by running the{' '}
            <BlogLink to="calculate-investment-portfolio-diversification-sharesight">
              Diversity Report
            </BlogLink>
            . The report gives users a detailed breakdown of their asset allocation based on the
            classification of their choice, including market, currency, sector, industry, investment
            type, country or a custom grouping determined by the investment attributes of their
            choice. This is one of the ways that Sharesight gives investors the important
            information they need to make informed investment decisions and rebalance their
            portfolio as needed, ensuring a diverse {stock} portfolio.
          </p>
        ),
      },
      {
        question: 'How to calculate investment performance over different time periods?',
        answer: (
          <p>
            Investors looking to see the impact of time on their performance will benefit from
            Sharesight’s annualised{' '}
            <BlogLink to="how-sharesight-calculates-your-investment-performance">
              performance calculation methodology
            </BlogLink>
            , which makes it easy to compare the relative performance of investments (and your
            portfolio) over the same and different time periods. For a more detailed portfolio
            performance comparison over different time periods, investors can use Sharesight's{' '}
            <BlogLink to="multi-period-report">Multi-Period Report</BlogLink>. This report allows
            investors to compare their portfolio returns over a choice of up to five distinct or
            cumulative periods, making it an effective way to see the impact of a portfolio
            rebalance, evaluate performance before and after a market correction or even compare a
            portfolio’s returns against fund manager performance periods.
          </p>
        ),
      },
      {
        question: 'How to calculate performance of investments in different currencies?',
        answer: (
          <p>
            Sharesight users with investments across different currencies can clearly see the impact
            of foreign currency fluctuations on their returns by viewing their total return on the
            Portfolio Overview page or by clicking into individual holdings. Investors can also use
            Sharesight's{' '}
            <BlogLink to="value-your-investments-in-any-currency-with-sharesight">
              Multi-Currency Valuation Report
            </BlogLink>{' '}
            to see the value of every investment in their portfolio denominated in both the currency
            it is traded in as well as its value when converted to any of the{' '}
            <BlogLink to="value-your-investments-in-any-currency-with-sharesight">
              {supportedCurrencies} currencies
            </BlogLink>{' '}
            Sharesight supports. This is a useful online investment portfolio tool for investors who
            invest in one currency, but ‘think’ in another currency, such as expats or global
            investors with investments across multiple markets and currencies.
          </p>
        ),
      },
      {
        question:
          'How to calculate the contribution of different investments to portfolio returns?',
        answer: (
          <p>
            Sharesight's{' '}
            <BlogLink to="explore-your-portfolio-performance-with-the-contribution-analysis-report">
              Contribution Analysis Report
            </BlogLink>{' '}
            is a useful tool for investors who want to see the impact of different investments on
            their total portfolio return. The report shows the contribution of different groups of
            investments to the portfolio’s performance, with the option to sort investments by
            marketcurrency, sector, industry, investment type, country or a custom grouping
            determined by the investment attributes of your choice. This allows investors to see the
            components that are driving returns, such as {stock} selection, asset allocation, or
            exposure to certain countries, sectors, or industries.
          </p>
        ),
      },
      {
        question: 'How to benchmark investment portfolio performance?',
        answer: (
          <p>
            With Sharesight, it’s possible to{' '}
            <BlogLink to="benchmark-your-portfolio-against-any-stock-etf-or-fund">
              benchmark investment portfolios
            </BlogLink>{' '}
            against over {trackedInvestments} global {stock}s, ETFs and {mutualFund}s, including
            major indices. This can be done simply by clicking ‘Add a benchmark’ on the Portfolio
            Overview page and choosing a benchmark that aligns with your investment goals, or tracks
            a basket of {stock}s that will give you a like-for-like comparison with your own
            portfolio. This is one strategy investors can use to compare portfolio performance with
            the broader market and put their returns into context, which is especially useful in
            times of market volatility.
          </p>
        ),
      },
      {
        question: "Who is Sharesight's investment performance report suitable for?",
        answer: (
          <p>
            Sharesight’s investment{' '}
            <BlogLink to="track-your-investments-with-sharesights-performance-report">
              Performance Report
            </BlogLink>{' '}
            is designed for self-directed investors who are looking for a detailed breakdown of
            their portfolio’s performance over the time period of their choice. The report displays
            the price, quantity and value of each of the investments within the portfolio, while
            also breaking down performance by factors including capital gains, dividends and
            currency. This makes it particularly useful if you’re managing your own investment
            portfolio and want to track investment performance for mixed-asset or global portfolios.
          </p>
        ),
      },
      {
        question: 'Why choose Sharesight’s investment performance reporting?',
        answer: (
          <p>
            Trusted by over {freeUsers} global investors, Sharesight gives investors the full
            picture of their performance by including the impact of capital gains, brokerage fees,
            dividends and foreign currency fluctuations when calculating returns. Sharesight also
            makes it easy for investors to track the performance of all of their investments in one
            place, with support for over {trackedInvestments} global {stock}s, ETFs and {mutualFund}
            s, plus more than{' '}
            <BlogLink to="supported-brokers-global">{supportedBrokers} brokers worldwide</BlogLink>.
            Access to an advanced suite of reporting tools, as well as the ability to track cash
            accounts, property and cryptocurrency along with the rest of your investments is just
            another reason that investors should consider using Sharesight to track their
            performance.
          </p>
        ),
      },
    ],
    [freeUsers, mutualFund, stock, supportedBrokers, supportedCurrencies, trackedInvestments]
  );
};

export const getPerformanceCards = ({ mutualFund, trackedInvestments, stock }: Translations) => [
  {
    cardIdentifier: 'track-1',
    title: 'Track all your investments',
    image: <Gauge />,
    text: (
      <>
        Track automatically updated price and performance data on over {trackedInvestments} {stock}
        s, ETFs and {mutualFund}s worldwide.
      </>
    ),
  },
  {
    cardIdentifier: 'track-2',
    title: 'Calculate your total return',
    image: <Calculator />,
    text: (
      <>
        See more than your broker shows you – calculate your annualised return including capital
        gains, <GatsbyLink to="/dividend-tracker">dividends</GatsbyLink> and currency fluctuations.
      </>
    ),
  },
  {
    cardIdentifier: 'track-3',
    title: 'Benchmark your portfolio',
    image: <ChartScatter />,
    text: (
      <>
        Compare against a world of investments – benchmark against any {stock}, ETF or {mutualFund}{' '}
        in Sharesight’s database.
      </>
    ),
  },
];
