import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { useGatsbyImage } from 'hooks/useGatsbyImage';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import {
  Container,
  HeroContainer,
  Section,
  SectionImage,
  SectionVideo,
} from 'components/Container';
import { Cards } from 'components/Cards';
import { CTANotice, Notice } from 'components/Notice';
import { TrustPilot } from 'components/TrustPilot';
import { Accordion } from 'components/Accordion';
import { BlogLink, SignupLink } from 'components/Links';
import { ContentBlock } from 'components/ContentBlock';
import videoThumbnail from 'images/shared-page-assets/youtube-thumbnails/marcus-customer-story.png';

import {
  getPerformanceCards,
  usePerformanceContent,
  usePerformanceFAQ,
} from 'page-data/investment-portfolio-performance';

import { SupportedBrokersBlock } from 'components/SupportedBrokersBlock';
import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const InvestmentPortfolioPerformance: FC<LocalizedPageProps> = () => {
  const {
    translations: { trackedInvestments, stock, mutualFund },
  } = useContentfulLocale();

  const performanceFAQ = usePerformanceFAQ();

  const performanceContent = usePerformanceContent();

  const performanceCards = getPerformanceCards({ mutualFund, trackedInvestments, stock });

  const portfolioCards = [
    {
      cardIdentifier: 'track-1',
      image: useGatsbyImage({
        name: 'investment-portfolio-performance/specify-custom-dimensions',
        alt: 'Specify custom dimensions',
      }),
      title: 'Specify custom dimensions',
      text: `Create your own Custom Groups to organise your holdings and specify your methodology (great for asset allocation reporting).`,
    },
    {
      cardIdentifier: 'track-2',
      image: useGatsbyImage({
        name: 'investment-portfolio-performance/label-your-holdings',
        alt: 'Label your holdings',
      }),
      title: 'Label your holdings',
      text: 'Further organise your holdings and create custom filtered reports on specific subsets of holdings within your portfolio.',
    },
    {
      cardIdentifier: 'track-3',
      image: useGatsbyImage({
        name: 'investment-portfolio-performance/view-wealth-holistically',
        alt: 'View your wealth holistically',
      }),
      title: 'View wealth holistically',
      text: ' Invest across both personal and retirement accounts? See the complete picture by running reports across multiple portfolios.',
    },
    {
      cardIdentifier: 'track-4',
      image: useGatsbyImage({
        name: 'investment-portfolio-performance/report-on-any-period',
        alt: 'Report on any period',
      }),
      title: 'Report on any period',
      text: 'Run your performance reports over preset timeframes, or specific dates, going back over 20 years.',
    },
    {
      cardIdentifier: 'track-5',
      image: useGatsbyImage({
        name: 'investment-portfolio-performance/export-your-reports',
        alt: 'Export your reports',
      }),
      title: 'Export your reports',
      text: 'Download your performance & tax reports to PDF, Excel or Google Sheets for additional analysis or safekeeping.',
    },
    {
      cardIdentifier: 'track-6',
      image: useGatsbyImage({
        name: 'investment-portfolio-performance/share-your-portfolio',
        alt: 'Share your portfolio',
      }),
      title: 'Share your portfolio',
      text: 'Share secure portfolio access with your accountant, financial advisor or family members, with tiered permission levels.',
    },
  ];

  return (
    <Layout>
      <Seo />
      <HeroContainer background="cream" skipColoration>
        <Section>
          <h1>Track your true investment performance</h1>
          <p>
            Sharesight shows your true investment returns that your broker doesn't. See your
            performance including dividends, currency fluctuations and more all in one place.
          </p>
          <br />
          <SignupLink asButton>Sign up for free</SignupLink>
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'investment-portfolio-performance/ipp-hero',
            alt: "User Interface of Sharesight's portfolio management tool featuring a performance line graph",
            loading: 'eager',
          })}
          boxShadow={false}
          borderRadius={false}
        />
      </HeroContainer>

      <Cards verticalAlign="top" cards={performanceCards} />

      <Container gap={15}>
        <Section>
          <h2>"The ability to go beyond the spreadsheet is what this is all about."</h2>
        </Section>
        <SectionVideo videoId="gPkPqizr0nE" thumbnailUrl={videoThumbnail} />
      </Container>
      <SupportedBrokersBlock />
      <CTANotice
        header="Unlock investor grade performance reporting"
        note="Get 10 holdings for free"
      />

      <Container>
        <Section>
          <h2>Understand the drivers of your performance</h2>
          <p>
            See how each of your holdings contributes to your overall portfolio performance, and
            easily identify non-performers with the{' '}
            <BlogLink to="explore-your-portfolio-performance-with-the-contribution-analysis-report">
              Contribution Analysis Report
            </BlogLink>
            .
          </p>
          <p>
            Group the investments in your portfolio along the dimensions of your choice to compare
            performance across different countries, industries, sectors and more.
          </p>
        </Section>
      </Container>

      {performanceContent.map(({ header, textContent, image }) => (
        <ContentBlock key={header} header={header} textContent={textContent} image={image} />
      ))}

      <Notice
        header="How Sharesight calculates your investment performance"
        button={
          <BlogLink
            to="how-sharesight-calculates-your-investment-performance"
            asButton
            appearance="primary"
          >
            Read performance article
          </BlogLink>
        }
        background="callout"
      />

      <Cards
        header="Get the most out of your portfolio"
        verticalAlign="top"
        cards={portfolioCards}
      />
      <TrustPilot tagId="performance" localizeTag={false} />
      <Accordion
        title="Performance questions"
        details={performanceFAQ}
        injectFAQStructuredData
        background="cream"
      />
    </Layout>
  );
};

export default InvestmentPortfolioPerformance;
